import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './index.css';

import SolanaWallets from 'solana-wallets-vue';

// You can either import the default styles or create your own.
import 'solana-wallets-vue/styles.css';

import { WalletAdapterNetwork } from "@solana/wallet-adapter-base"




import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
} from '@solana/wallet-adapter-wallets';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

// const walletOptions = {
//   wallets: [
//     getPhantomWallet(),
//     getSlopeWallet(),
//     getSolflareWallet(),
//     // new SlopeWalletAdapter(),
//     new SolflareWalletAdapter({ network: WalletAdapterNetwork.Devnet }),
//   ],
//   autoConnect: true,
// }

let notificationsOptions = {}
createApp(App).use(router).use(VueLoading).mount('#app');
