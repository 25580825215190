
import { defineComponent, nextTick, onMounted, ref, watch } from 'vue';
import useWallet from '@/composables/wallet';
import useCluster from '@/composables/cluster';
import { initGemFarm } from '@/common/gem-farm';
import { PublicKey } from '@solana/web3.js';
import ConfigPane from '@/components/ConfigPane.vue';
import FarmerDisplay from '@/components/gem-farm/FarmerDisplay2.vue';
import Vault from '@/components/gem-bank/Vault.vue';
import { INFT } from '@/common/web3/NFTget';
import { findFarmerPDA, stringifyPKsAndBNs } from '@gemworks/gem-farm-ts';
import { WalletMultiButton } from 'solana-wallets-vue'
import { createToaster } from "@meforma/vue-toaster";
import { vault } from '@metaplex/js/lib/programs';
import { web3 } from '@project-serum/anchor';
import { base64 } from '@project-serum/anchor/dist/cjs/utils/bytes';
import { deserializeUnchecked } from 'borsh';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { computed } from '@vue/reactivity';




export default defineComponent({
  components: { Vault, FarmerDisplay, ConfigPane,WalletMultiButton,Loading },
  setup() {
    const { wallet, getWallet } = useWallet();
    const { cluster, getConnection } = useCluster();
    
    const WH_FARMS_DEV =  {
      gen1:"6gpix9t1JqNfqJkfkZQwQAWgck8c7rTzweg4YGzyU32y",
      // gen1:"4peqUvS62LPCD37TWiUkCpVxpfouWV7v2vNm9EgSnYaF",
      // gen2:"D52HQeM5VLoRP3w6jRobGBRTNTJUdkzZ8vyd4juYv3Hn"
      gen2:"4peqUvS62LPCD37TWiUkCpVxpfouWV7v2vNm9EgSnYaF"
    }
    
    const WH_FARMS_PROD = {
      gen1:"3g1Nb4tZfadW4gKYnG8KEjmYKUfWEzLnr9CEXPcKLAaW",
      gen2:"8WgbaNv6rim2BrPSo2vUXN7QXd6uhZ8YXgJqUyrkPrsg",
      OWNER:"FiCccYVPv7yPp22JHmsBwbxHLKt48z5xeF3HH3gmc9KW"
    }
    const WL_CREATORS_PROD = {
      gen1:"HxdMLcBYoRtvMrLFa51MfyDWRydFi28tiEnFjttRaLnm",
      gen2:"EYPjGpJckfyjN6bbWsh3qTwPsGuiiQsPzTr3QQShZX5h",
    }

    const WL_CREATORS_DEV = {
      gen1:"8Jy6LqKiKfS4kADB392ov54Z26SLLUNLj3jzNf8QGQtY",
      gen2:"DBrCYpPkGKZ3ed6knH1ifRpzN3Nsaa1soHbmBnHFw457",
    }
    const isDev = 0;
    const WL_CREATORS = isDev? WL_CREATORS_DEV : WL_CREATORS_PROD;
    const WH_FARMS = isDev? WH_FARMS_DEV : WH_FARMS_PROD ;
    let gf: any;
    watch([wallet, cluster], async () => {
      await freshStart();
    });

    //needed in case we switch in from another window
    onMounted(async () => {

      await freshStart();
    });
    
    // --------------------------------------- farmer details
    const farm = ref<string>();
    const farmAcc = ref<any>();

    const farmerIdentity = ref<string>();
    const farmerAcc = ref<any>();
    const farmerState = ref<string>();

    const availableA = ref<string>();
    const availableB = ref<string>();

    const showStakingButton = ref<Boolean>();

    const isLoading = ref<Boolean>();

    function showToast(text:string,toastType:string){
        let toast = createToaster({})
        toast.show(text,{ type : toastType })

    }
    const startLoading = () => isLoading.value = true; 
    const stopLoading = () => isLoading.value = false; 

    const getRewardCount = computed(()=>{
      if(availableB.value && availableB.value != '0'){
        let weeh = (parseInt(availableB.value)/1e9).toFixed(3)
        
        return parseFloat(weeh) == 0? '0' : weeh;
      }
      return '0';
    })
    //auto loading for when farm changes
    watch(farm, async () => {
      await freshStart();
    });

    const updateAvailableRewards = async () => {
      availableA.value = farmerAcc.value.rewardA.accruedReward
        .sub(farmerAcc.value.rewardA.paidOutReward)
        .toString();
      availableB.value = farmerAcc.value.rewardB.accruedReward
        .sub(farmerAcc.value.rewardB.paidOutReward)
        .toString();
    };

    const fetchFarn = async () => {
      farmAcc.value = await gf.fetchFarmAcc(new PublicKey(farm.value!));
      console.log(
        `farm found at ${farm.value}:`,
        stringifyPKsAndBNs(farmAcc.value)
      );
    };

    const fetchFarmer = async () => {
      
      const [farmerPDA] = await findFarmerPDA(
        new PublicKey(farm.value!),
        getWallet()!.publicKey!
      );
      farmerIdentity.value = getWallet()!.publicKey?.toBase58();
      farmerAcc.value = await gf.fetchFarmerAcc(farmerPDA);
      farmerState.value = gf.parseFarmerState(farmerAcc.value);
      try{

        await updateAvailableRewards();
      }
      catch(e){
        showToast("Error! Please try again.",'error')
      }
      finally{

        stopLoading()      
      }
      console.log(
        `farmer found at ${farmerIdentity.value}:`,
        stringifyPKsAndBNs(farmerAcc.value)
      );
    };

    const freshStart = async () => {
      if (getWallet() && getConnection()) {
        gf = await initGemFarm(getConnection(), getWallet()!);
        farmerIdentity.value = getWallet()!.publicKey?.toBase58();

        //reset stuff
        farmAcc.value = undefined;
        farmerAcc.value = undefined;
        farmerState.value = undefined;
        availableA.value = undefined;
        availableB.value = undefined;

        try {
          isLoading.value = true
          await fetchFarn();
          await fetchFarmer();
          isLoading.value = false
        } catch (e) {
          isLoading.value = false;
          showToast("Farmer account not found!",'info');
          console.log(`farm with PK ${farm.value} not found :(`);
        }
      }
    };

    const initFarmer = async () => {
      startLoading()
      try{
        await gf.initFarmerWallet(new PublicKey(farm.value!));
        await fetchFarmer();
      }
      catch(e){
        stopLoading()
        showToast("Timeout! Please try again.","error")
      }
      stopLoading()
    };

    // --------------------------------------- staking
    const beginStaking = async () => {
      startLoading()
      try{
        await gf.stakeWallet(new PublicKey(farm.value!));
        await fetchFarmer();
      }
      catch(e){
        showToast("Error while staking. Please try again!","error")
      }
      finally{
        stopLoading()
      }
      selectedNFTs.value = [];
    };

    const endStaking = async () => {
      startLoading()
      try {
        await gf.unstakeWallet(new PublicKey(farm.value!));
        await fetchFarmer();
      } catch (error) {
        showToast("Error! Please try again.","error")
      }
      finally{
        stopLoading()
      }
      selectedNFTs.value = [];
    };

    const claim = async () => {
      startLoading()

      try{

        await gf.claimWallet(
          new PublicKey(farm.value!),
        new PublicKey(farmAcc.value.rewardA.rewardMint!),
        new PublicKey(farmAcc.value.rewardB.rewardMint!)
        );
        await fetchFarmer();
      }
      catch(e){
        showToast("Error! Please try again.","error")
      }
      finally{
        stopLoading()
      }
    };

    const handleRefreshFarmer = async () => {

      await fetchFarmer();
    };

    // --------------------------------------- adding extra gem
    const selectedNFTs = ref<INFT[]>([]);

    const handleNewSelectedNFT = (newSelectedNFTs: INFT[]) => {
      console.log(`selected ${newSelectedNFTs.length} NFTs As`);

      if(farmerState.value != "unstaked" && !showStakingButton.value){
        selectedNFTs.value = []
        console.log('unselected called:')
        let toast = createToaster({})
        toast.show("End Staking & Cooldown to add more NFTs to the vault.",{type:"error"})

        return;
      }
      // console.log('vault',farmerAcc.value)
      // if()
      selectedNFTs.value = newSelectedNFTs;
    };

    const addSingleGem = async (
      gemMint: PublicKey,
      gemSource: PublicKey,
      creator: PublicKey
    ) => {
      await gf.flashDepositWallet(
        new PublicKey(farm.value!),
        '1',
        gemMint,
        gemSource,
        creator
      );
      await fetchFarmer();
    };

    const addGems = async () => {
      await Promise.all(
        selectedNFTs.value.map((nft) => {
          const creator = new PublicKey(
            //todo currently simply taking the 1st creator
            (nft.onchainMetadata as any).data.creators[0].address
          );
          console.log('creator is', creator.toBase58());

          addSingleGem(nft.mint, nft.pubkey!, creator);
        })
      );
      console.log(
        `added another ${selectedNFTs.value.length} gems into staking vault`
      );
    };

    function handleVaultUpdated(vaultAcc){
      console.log('vault acc:',vaultAcc)

    }
    function handleVaultGdrs(foundGDRs){

      showStakingButton.value = foundGDRs && foundGDRs.length ? true : false;
      console.log('found gdrs:',foundGDRs)

    }
    return {
      WH_FARMS,
      WL_CREATORS,
      wallet,
      farm,
      farmAcc,
      farmer: farmerIdentity,
      farmerAcc,
      farmerState,
      availableA,
      availableB,
      initFarmer,
      beginStaking,
      endStaking,
      claim,
      handleRefreshFarmer,
      selectedNFTs,
      handleNewSelectedNFT,
      addGems,
      handleVaultUpdated,
      handleVaultGdrs,

      showStakingButton,
      isLoading,
      startLoading,
      stopLoading,
      getRewardCount,
    };
  },
});
