
import { defineComponent } from 'vue';
import FixedScheduleDisplay from '@/components/gem-farm/FixedScheduleDisplay.vue';
import { parseDate } from '@/common/util';
import numeral from 'numeral';

export default defineComponent({
  components: { FixedScheduleDisplay },
  props: {
    reward: Object,
    farmReward: Object,
    title: String,
  },

  setup(props) {
    const parseRewardType = (reward: any): string => {
      //returns "variable" or "fixed"
      return Object.keys(reward.rewardType)[0];
    };

    function toFixedDecimals(number:any){
      let val  = (parseInt(number) / 1e9 ).toFixed(3) 
      return val == '0.000' ? '0' : val;  
    }
    // function 

    return {
      parseRewardType,
      parseDate,
      numeral,
      toFixedDecimals
    };
  },
});
