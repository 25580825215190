<template>
  <div class="relative h-full min-h-screen wh-base">
    <!-- <TheNavBar /> -->
    <div class="pt-10 flex flex-col justify-center align-middle items-center">
      
      <a href="/"><img :width="400" class="pb-5"  src="./assets/weedheads-rectangle.png" alt="" ></a>
      <p class="text-4xl pt-3 px-1 mt-1 text-black underline">WEED FARM</p>
      <!-- <img :width="50" :height="50" src="./assets/400x600.gif" /> -->
      <!-- <p class="text-4xl pt-3 px-2 mt-1 text-black underline">STAKE FARM</p> -->
    </div>
    <!-- <div class="italic mt-5 text-center">by Gemworks</div> -->

    <div class="p-10">
      <router-view />
    </div>

    <div class="pt-10"></div>
    <!-- <TheCat /> -->
    <!-- <TheFooter /> -->
  </div>
</template>

<script>
import TheNavBar from '@/components/TheNavBar';
import TheFooter from '@/components/gem-farm/TheFooter';
import TheCat from '@/components/gem-farm/TheCat';
export default {
  components: { TheCat, TheFooter, TheNavBar },
};
</script>

<style>
* {
  font-family: 'Press Start 2P', monospace;
  /* background: ; */
}

.wh-base{
    background: url('./assets/bg1.jpg');
    /* background: url('https://rarity.weedheads.live/static/media/bg-dark.31ca3a14cb5354fa25b5.jpg'); */
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
input[type='radio']:checked + span {
  @apply text-black;
}
</style>
